import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import StatusAlert from "../../components/alerts/StatusAlert"
import SessionCard from "../../components/cards/SessionCard"
import SessionCardComplete from "../../components/cards/SessionCardComplete"


import { useState } from "react"



export default function Pages({data}) {

return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div className="">
  <div className="">
  <a href="/projects"><Type type="p6">&lt; Back</Type></a>
  <Type type="p2">{data.airtable.data.Name}</Type>
  {data.airtable.data.ClientOrganisation ?
    <Type type="p3" nopadding="yes">{data.airtable.data.ClientOrganisation}</Type>
    :
    undefined
  }
  {data.airtable.data.ClientEmail ?
    <Type type="p4"><a href={`mailto:${data.airtable.data.ClientEmail}`}>{data.airtable.data.ClientContactName}</a></Type>
    :
    undefined    
  }



<div className="flex flex-row space-x-8 mb-16">
  <div className="">
    <a href={data.airtable.data.InsightsPath}>
    <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
    <div>Insights</div></button></a>
  </div>
  <div className="">
    <a href={data.airtable.data.DovetailURL}>
    <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
    <img
      src="https://ik.imagekit.io/pathventures/systems/Dovetail"
      alt="Dovetail"
      className="h-8"/><div>Dovetail</div></button></a>
  </div>
  <div className="">
    <a href={data.airtable.data.ProjectURL}>
    <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
    <img
      src="https://ik.imagekit.io/pathventures/systems/GoogleDrive"
      alt="Google Drive"
      className="h-8"/><div>Project Folder</div></button></a>
  </div>
  <div className="">
    <a href={data.airtable.data.AskableURL}>
    <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
    <img
      src="https://ik.imagekit.io/pathventures/systems/Askable"
      alt="Google Drive"
      className="h-8"/><div>Askable booking</div></button></a>
  </div>
  <div className="">
    <a href={data.airtable.data.DiscussionPath}>
    <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
    <div>Discussion Guide</div></button></a>
  </div>  

  </div>



  <div className="mb-16">
<div className="mt-4">
  <Type type="h5">Research Objectives</Type>
  <div className="grid grid-cols-3 gap-8 mb-8">
  {data.airtable.data.ResearchObjectives.map((ObjectivesArray) => (
    <div className="bg-yellow/25 rounded-lg p-8 text-center flex items-center"><Type type="p5" nopadding="yes">{ObjectivesArray.data.Objective}</Type></div>
  ))}
  </div>
</div>
<div className="mt-4">
  <Type type="h5" nopadding="yes">Brief</Type>
  <Type type="p6">{data.airtable.data.ProjectBrief}</Type>
</div>
<div className="mt-4">
  <Type type="h5" nopadding="yes">Participant Criteria</Type>
  <Type type="p6">{data.airtable.data.ParticipantCriteria}</Type>
</div>
<div className="mt-4">
  <Type type="h5" nopadding="yes">Protocol</Type>
  {data.airtable.data.Protocols.map((ProtocolsArray) => (
    <div>
      <Type type="p6">{ProtocolsArray.data.Name}</Type>
      <div>
      {ProtocolsArray.data.ProtocolMethods.map((MethodsArray) => (
        <div>{MethodsArray.data.OrderedMethodText}</div>
      ))}
      </div>
      <div className="mt-2"><Type type="h6">{ProtocolsArray.data.AdjustedTime}m</Type></div>
    </div>
  ))}
</div>





  </div>
  
  {data.airtable.data.Sessions
    ?
 <>
  <Type type="h4" nopadding="yes">Completed Sessions</Type>

<Collapsed>
  <div className="flex flex-col space-y-8 mb-16">
   {data.airtable.data.Sessions.map((CompletedSessionsArray) => (
     <SessionCardComplete status={CompletedSessionsArray.data.SessionStatus} session={CompletedSessionsArray}/>
      ))} 
    </div>
</Collapsed>
  <Type type="h4" nopadding="yes">Remaining Sessions</Type>
<Collapsed status="open">
  <div className="flex flex-col space-y-8">
 {data.airtable.data.Sessions.map((SessionsArray) => (
   <SessionCard status={SessionsArray.data.SessionStatus} session={SessionsArray}/>
    ))} 
  </div>
</Collapsed>
  </>
:
<div className="bg-red/10 p-4 rounded-lg text-center">
  <Type type="h6" nopadding="yes">No sessions added yet</Type>
</div>
 }


  </div>
 
 
  
 </div>
 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Sessioms</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        DiscussionPath
        InsightsPath
        ProjectBrief
        LeadResearcher
        ResearchObjectives {data {
            Objective
          }}
        ClientContactName
        ClientEmail
        ClientOrganisation
        DovetailURL
        AskableURL
        ParticipantCriteria
        ProjectURL
        Sessions {data {
          Name
          Date
          Day
          Time
          Booked
          SessionStatus
          ParticipantSegment
          ResearcherFullName
          InterviewURL
          ObserverURL
          Participants {data {
            Name
            Age
            Gender
            Segment
            ParticipantSegment
          }}
        }}
        Protocols { data {
          Name
          AdjustedTime
          ProtocolMethods {data {
            Name
            OrderedMethodText
          }}
        }}
      }
    }
  }
`
