import React from "react"
import Type from "../../components/typography/Type"
import Collapsed from "../../components/controls/Collapsed"
import StatusAlert from "../../components/alerts/StatusAlert"


const SessionCardComplete = (props) => {	
	
const status = props.status; 	
const session = props.session;
		
	switch (status) {
		  
		
		case "Complete":
			  return (
			  <div className="w-full  rounded-lg bg-black/5 p-8">
			  
				  <div className="flex flex-col lg:flex-row lg:space-x-12">
					  <div className="grow">
						<Type type="h3" nopadding="yes">{session.data.Name}</Type>
						{session.data.Booked ? <Type type="p5" nopadding="yes">{session.data.Day}<br />{session.data.Time}</Type> : <span></span>}

					  </div>
					  <div className="">
				   <StatusAlert  status={session.data.SessionStatus} />
				   </div>
				   </div>
					<Collapsed>
					
				  <div className="border-t border-black pt-4">
				  <div className="flex flex-row space-x-16">
			
					<div>
					  <Type type="h6">Researcher</Type>
					  <div className="bg-yellow px-4 py-2 rounded-full"><Type type="p6" nopadding="yes">{session.data.ResearcherFullName}</Type></div>
					</div>
					<div>
					  <Type type="h6">Participant</Type>
					  <div className="flex flex-row space-x-4">
					  {session.data.Participants.map((ParticipantsArray) => (
						<>
						  <div className="bg-blue px-4 py-2 rounded-full "><Type type="p6" nopadding="yes">{ParticipantsArray.data.Name}</Type></div>
						  <div className="bg-blue px-4 py-2 rounded-full "><Type type="p6" nopadding="yes">{ParticipantsArray.data.Segment}</Type></div>
						  <div className="bg-blue px-4 py-2 rounded-full"><Type type="p6" nopadding="yes">{ParticipantsArray.data.Age}</Type></div>
						  <div className="bg-blue px-4 py-2 rounded-full"><Type type="p6" nopadding="yes">{ParticipantsArray.data.Gender}</Type></div>
					  </>
					  ))}
					  </div>
					</div>
				  </div>
				  <div className="flex flex-row space-x-8 mt-12">
					<div>
					  <a href={session.data.InterviewLink}>
				  <button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
				  <img
					src="https://ik.imagekit.io/pathventures/systems/Askable"
					alt="Google Drive"
					className="h-8"/><div>Remote session</div></button></a>
				  </div>
				  <div className="">
					<a href={session.data.AnalysisLink}>
					<button className="mt-2 border border-black/50 hover:border-black px-6 py-4 rounded-lg flex flex-row space-x-4 font-bold text-lg items-center">
					<img
					  src="https://ik.imagekit.io/pathventures/systems/Dovetail"
					  alt="Dovetail"
					  className="h-8"/><div>Analysis</div></button></a>
				  </div>
				  </div>
				  </div>
					</Collapsed>
				</div>
			  
			  
			
		  );
		
	  default:
	  return (
		 <></>
		 
	  );
	};	
};

export default SessionCardComplete;
